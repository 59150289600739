:root {
  --black: #242d49;
  --glass: rgba(255, 255, 255, 0.54);
}

body {
  margin: 0;
  background:;
  font-family: 'Comic Neue', cursive;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App {
  color: var(--black);
  background: linear-gradient(
    106.37deg,
    #ffe1bc 29.63%,
    #ffcfd1 51.55%,
    #f3c6f1 90.85%
  );
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.AppGlass {
  display: grid;
  height: 70%;
  width: 70%;
  background: var(--glass);
  border-radius: 2rem;
  display: flex;
  flex-direction: column; 
  gap: 16px;
  grid-template-columns: 11rem auto 20rem;
  overflow: hidden;
}

p { 
  color: black;
  font-size: 150%;
  font-weight: bold;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
